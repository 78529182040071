import React, { createContext, useContext, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
// Define the initial state
const initialState = {
  isAuthenticated: false,
  user: null,
}

// Create a reducer function to handle authentication actions
const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      }
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      }
    default:
      return state
  }
}

// Create the AuthContext
const AuthContext = createContext()

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState)

  // Check for authentication state in local storage on component mount
  useEffect(() => {
    const storedAuthState = localStorage.getItem('authState')
    if (storedAuthState) {
      const parsedAuthState = JSON.parse(storedAuthState)
      dispatch({ type: 'LOGIN', payload: parsedAuthState.user })
    }
  }, [])

  const login = (user) => {
    dispatch({ type: 'LOGIN', payload: user, privileges: user.usersData })
    // Store the authentication state in local storage
    localStorage.setItem('authState', JSON.stringify({ user }))
    localStorage.setItem('privileges', JSON.stringify(user.usersData))
  }

  const logout = () => {
    // Clear the authentication state from local storage
    localStorage.removeItem('authState')
    dispatch({ type: 'LOGOUT' })
  }

  AuthProvider.propTypes = {
    children: PropTypes.node.isRequired, // Validate that children is required and should be a node
  }

  return <AuthContext.Provider value={{ ...state, login, logout }}>{children}</AuthContext.Provider>
}
